.aboutbg1{
    /* background-image: url('../img/back3.jpg'); */
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Parallax Effect for DIV 3 */
    /* min-height:700px; */
    margin-top: 32px;
}
.beans{
    background-position: 'fixed';
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marck+Script&display=swap');
/* .left-tt{
    display: flex;
    gap: 2rem;
    flex: 1 1;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
} */

.left-t>:nth-child(4)
{
text-transform: none;
text-align: justify;
letter-spacing: 2px;
line-height: 40px;
}
.right-tt{
    flex: 1;
    position: relative;
     
}
/* .imgg{
    width: 17rem;
    height: 20rem;
    position: absolute;
   
    right: 307px;
    top:5rem;   
} */


/* .arrow{
    display: flex;
    gap:1rem;
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    
} */
.arrow>img{
    width: 19%;
    cursor: pointer;
}
.bggg{
    background-image: url('../img2/b2.jpg');
    background-repeat: no-repeat;
    border-bottom-left-radius: 372px;
    /* border-top-left-radius: 61px; */
   width: 140%;
   background-position: center center;
   background-repeat: no-repeat;
   background-size: cover;
   /* height: 600px; */


  
}
.imggg{
    width: 48%;
    /* margin-left: 132px; */
    /* padding-top: 3%;
    padding-bottom: 3%; */
}

/* .bgarrow{
  background-image: url('../img/bgwhite.png');
    background-position: 502px 50px; 

    background-attachment: fixed;
} */


@media only screen and (max-width: 1000px) {
  .yt{
    text-align: center;
  }
    .aboutflex{
        flex-wrap: wrap;
    }
    .left-hh{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
       
    .imggg {
        width: 24%;
    /* width: 517px; */
    height: 100%;
    margin-left: 0px !important;

    margin-left: -4px;
    }
    .right-hh{
        flex-direction: row-reverse;
        justify-content: center;
        margin-top: 36px;
    }
    .arrow{
        display: none !important;
    }
    .mediaQ {
        display: flex !important;
        justify-content: center;
    /* margin-top: 48px; */
    gap: 2rem;
    width: 100%;
    }
    
    .mediaQ>img{
        height: 26vh;
    }
  
    .bggg{
        border-bottom-left-radius: 71px;
        margin-right: 0px;
    }
    .image-coff{
        display: none;
    }
    .right-hh {
        width: 79%;
    }
    .flavour-heading{
        text-align: center;
        

    display: flex !important
    }
    .bggg {
        background-image:none;
        background-repeat: no-repeat;
        border-bottom-left-radius: 372px;
        /* border-top-left-radius: 61px; */
        width: 140%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        /* height: 600px; */
    }
    }

    

    .mediaQ{
        display: none;

    }
    @media only screen and (max-width: 600px) {
        .mediaQ>img{
            height: 16vh;
        }
        .font66{
            font-size: 20px !important;
        }
   
    }
    /* .bgarrow>img{
        width: 25%;

    }
     */
 
     .flavour-heading{
     display: none;

     }

    
     .arrow:hover>img{
     
                 
    }

    /* .arrow1 {
        transition: transform 1s;
       } */

   .boxx{
    display: flex;
    justify-content: center;
   }
   .boxxx {
    transition: transform 1s;
   }
   .boxx:hover>img { 
    transform: scale(1.3); 
}
.boxxx:hover>img { 
    transform: scale(1.2); 
}
/* .mediaQ:hover>img { 
    transform: scale(1.3); 
} */

.bg56{
    background-color: white;
    border-bottom-left-radius:32px ;
    border-top-left-radius:32px ;
    width: 40%;
    align-items: center;
    margin-left: 118px;
    height:fit-content;

}
.heading8{
    letter-spacing: 5px;
    font-size: 40px;
    font-weight: 100;
}
.font66{
    font-family: 'Questrial', sans-serif;
}
@media only screen and (max-width: 1200px) {
    .imggg {
        width: 54%;
        /* margin-left: 132px; */
        padding-top: 3%;
        padding-bottom: 3%;
    }
    .bg56 {
    
        margin-left: 60px;
    }

}
@media only screen and (max-width: 1180px) {
    .imggg {
        width: 55%;
        /* margin-left: 132px; */
        padding-top: 3%;
        padding-bottom: 3%;
    }
    .bg56 {
    
        margin-left: 60px;
    }

}
.img0{
   background-image: url('../img2/leaf-grey.svg'); 
   background-repeat: no-repeat;
   background-position: 0px 24px;
}
@media only screen and (max-width: 1000px) {
    .imggg {
        width: 35%;
        /* margin-left: 132px; */
        padding-top: 3%;
        padding-bottom: 3%;
    }
}
.font78{
    font-family: 'Montserrat', sans-serif;
}

.heading8{
    letter-spacing: 2px;
    font-size: xxx-large;
    text-transform: uppercase;
}
.status{
    margin-top: 50px;
    /* display: flex; */
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
.status-svg{
    font-size: 22px;
    width: 55px;
    display: flex;
}