:root {
    --effect: hover 1.5s linear infinite;
  }
  *{
     /* margin:0; */
  }
  
  .body55 {
    display: flex;
    width: 100vw;
    height: 100vh;
    background: black;
    align-items: center;
    justify-content: center;
  }
  
  .loader1 {
    text-align: center;
  }
  



  .imageLogoLoader{
    width: 100px;
  }
  .p {
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    font-size: 4em;
    font-family: arial;
    font-weight: 600;
    transform: scale(.5);
    color: #121212;
    -webkit-text-stroke: 2px gray;
  }
  
  .p1 {
    animation: var(--effect);
  }
  
  .p2 {
    animation: var(--effect) .125s;
  }
  
  .p3 {
    animation: var(--effect) .25s;
  }
  
  .p4 {
    animation: var(--effect) .375s;
  }
  
  .p5 {
    animation: var(--effect) .5s;
  }
  
  .p6 {
    animation: var(--effect) .675s;
  }
  
  .p7 {
    animation: var(--effect) .75s;
  }
  .p8 {
    animation: var(--effect) .875s;
  }
  
  @keyframes hover {
    0% {
      transform: scale(.5);
      color: #121212;
      -webkit-text-stroke: 2px gray;
    }
  
    20% {
      transform: scale(1);
      color: pink;
      -webkit-text-stroke: 3px rgb(117,76,41);
      /* filter: drop-shadow(0 0 1px black)drop-shadow(0 0 1px black)drop-shadow(0 0 3px red)drop-shadow(0 0 5px red)hue-rotate(10turn); */
    }
  
    50% {
      transform: scale(.5);
      color: #121212;
      -webkit-text-stroke: 2px gray;
    }
  
  
  }
  