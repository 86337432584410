/**
*
* Widescreen Devices
*
**/

@media only screen and (min-width: 1200px) {
	#page-content-wrapper:not(.wide),
	.standard-wrapper,
	.footer-main-container-wrapper,
	#footer ul.sidebar-widget,
	#page-content-wrapper.wide:not(.photoframe) .standard-wrapper,
	.ppb_popup.one.contain,
	#blog_promo_link_wrapper,
	#menu-wrapper,
	.above-top-menu-bar .page-content-wrapper:not(.wide) {
		max-width: 1350px;
		width: 100%;
		box-sizing: border-box;
		margin: auto;
		padding: 0 90px;
	}
	body.tg-single-course-3 .standard-wrapper,
	body.tg-single-course-4 #single-course-meta.standard-wrapper,
	body.tg-single-course-1 #single-course-meta.standard-wrapper,
	body.tg-single-course-1 #single-course-header .standard-wrapper {
		padding: 0;
	}
	#page-content-wrapper.blog-wrapper {
		padding: 0;
		width: calc(100% - 180px);
		margin-bottom: 60px;
		margin-top: 60px;
		max-width: 1170px;
	}
	#footer ul.sidebar-widget {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	#page-content-wrapper.wide.nomargin,
	.page-content-wrapper.wide.nomargin {
		margin-top: 0;
	}
	#page-content-wrapper.wide:not(.photoframe),
	.page-content-wrapper.wide:not(.photoframe),
	#page-content-wrapper.wide:not(.split),
	.page-content-wrapper.wide:not(.split) {
		width: 100%;
		max-width: 100%;
		padding: 0;
	}
	.footer-main-container-wrapper {
		padding-top: 30px;
		padding-bottom: 20px;
	}
	#page-content-wrapper.fullwidth,
	.page-content-wrapper.fullwidth {
		width: 100%;
		padding: 0;
		max-width: 100%;
	}
	.mobile-menu-wrapper .page-content-wrapper {
		padding: 0 !important;
	}
	#logo-wrapper .social-profile-wrapper {
		left: 90px;
	}
	#logo-right-wrapper {
		right: 90px;
	}
}


/* 
#Tablet (Landscape)
================================================== 
*/


@media screen and (max-width: 667px) and (orientation:landscape) {
	body {
		-webkit-text-size-adjust: none;
	}
}
/* @media only screen and (min-width: 500px){

} */
@media   (max-width: 500px) {


.font2{
	font-size: 75px !important;
}
.font5{
	/* font-size: 75px !important; */

}
.since{
	margin-top: -110px;
}
}
/* @media only screen and (max-width: 1190px) {
	.bg-img{
		margin-top: -23px !important;
  }
} */
.star{
	 color:#C7A17A;
}
.font2{
    font-size: 100px;
    margin-top:-20px;
    color: #C7A17A;
	
}
.font5{
	font-size: 76px;
    letter-spacing: 7px;
    margin-top: -111px;
    color: white;
}
.since{
	
		font-size: 30px;
		letter-spacing: 4px;
		 margin-top:-85px;
	  
}
.bgg{
	padding-top:76px; 
	width: 100%;
}
#vertical1{
 margin-top: -27px;
  height: 143px;

}
.tt{
   margin-top: 200px;
}
.coffeepic-1{
		width: 324px;
		position: absolute;
		margin-left: 137px;
		
}
.our{
		position:relative;
		margin-top: 244px;
		margin-left: 305px;
       font-size: 5rem;
	
}
.para1{
		margin-top :46px;
		margin-right: 47px;
}